import logo_next_days from "../../img/password.png";
import { Formik } from "formik";
import {
  messageError,
  messageSuccess,
  userOTP,
  userPhoneNumber,
} from "../../helper";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "./apollo";
export default function ChangePasswordUser() {
  const history = useHistory();

  const [changePassword] = useMutation(CHANGE_PASSWORD);
  return (
    <Formik
      initialValues={{
        password: "",
        ConFirmPassword: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານກ່ອນ!";
        }
        if (!values.ConFirmPassword) {
          errors.ConFirmPassword = "ກະລຸນາຢືນຢັນລະຫັດຜ່ານກ່ອນ!";
        }

        if (
          values?.ConFirmPassword &&
          String(values?.ConFirmPassword)?.length < 8
        ) {
          errors.ConFirmPassword =
            "ກະລຸນາປ້ອນຢ່າງໜ້ອຍ 8 ໂຕເລກ " +
            String(values?.ConFirmPassword)?.length +
            " ຕົວເລກ";
        }
        if (values?.password && String(values?.password)?.length < 8) {
          errors.password =
            "ກະລຸນາປ້ອນຢ່າງໜ້ອຍ 8 ໂຕເລກ " +
            String(values?.password)?.length +
            " ຕົວເລກ";
        }
        if (values?.password !== values.ConFirmPassword) {
          errors.ConFirmPassword = "ລະຫັດຜ່ານບໍ່ຕົງກັນ";
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let { data: _changePassword } = await changePassword({
            variables: {
              data: {
                password: String(values.password),
              },
              where: {
                otp: parseInt(userOTP()),
                phone_number: parseInt(userPhoneNumber()),
              },
            },
          });
          if (_changePassword?.changePassword?.status === "success") {
            messageSuccess("ຂໍລະຫັດສຳເລັດແລ້ວ");
            setTimeout(async () => {
              window.open(`https://nextday.anousith.express`);
            }, 2000);
            history.push("/success/user");
            resetForm();
          }
        } catch (error) {
          console.log(error);
          messageError("ການບັນທືກຂໍ້ມູນຜິດພາດ");
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,

        /* and other goodies */
      }) => (
        <>
          <div className="mt-5">
            <div className="card">
              <div className="card-body">
                <center>
                  <img
                    src={logo_next_days}
                    className="mt-3"
                    style={{
                      width: 150,
                      height: 150,
                    }}
                  />
                </center>
                <h2 className="text-center fw-bolder text-danger p-2">
                  ສ້າງລະຫັດຜ່ານໃໝ່
                </h2>
                <div className="form-group mb-2">
                  <label>ປ້ອນລະຫັດຜ່ານ</label>
                  <input
                    type="password"
                    className={
                      errors.password
                        ? "form-control is-invalid"
                        : "form-control invalid"
                    }
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="********"
                    style={{
                      borderRadius: "10px",
                      height: "50px",
                      fontSize: "20px",
                    }}
                  />
                  <span className="text-danger">{errors.password}</span>
                </div>

                <div className="form-group mb-2">
                  <label>ຢືນຢັນລະຫັດຜ່ານ</label>
                  <input
                    type="password"
                    className={
                      errors.ConFirmPassword
                        ? "form-control is-invalid"
                        : "form-control invalid"
                    }
                    name="ConFirmPassword"
                    value={values.ConFirmPassword}
                    onChange={handleChange}
                    placeholder="********"
                    style={{
                      borderRadius: "10px",
                      height: "50px",
                      fontSize: "20px",
                    }}
                  />
                  <span className="text-danger">{errors.ConFirmPassword}</span>
                  <br />
                </div>

                <button
                  type="button"
                  className="btn btn-success btn-block btn-lg"
                  onClick={() => handleSubmit()}
                  style={{ borderRadius: "10px" }}
                >
                  <i className="icon-check-circle" /> ຢືນຢັນປ່ຽນລະຫັດຜ່ານ
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
