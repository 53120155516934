import logo_next_days from "../../img/msg.png";
import { Formik } from "formik";
import { messageError, messageSuccess, userPhoneNumber, USER_KEY } from "../../helper";
import { AUTHENTICATION, VERIFY_CODE } from "./apollo";
import { useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import Countdown from "react-countdown";
import { useEffect, useState } from "react";
export default function ConFirmOTPUser() {
  const history = useHistory();
  const client = useApolloClient();
  const [verifyCode] = useMutation(VERIFY_CODE);
  let [checkOTP, setCheckOTP] = useState(0);
  const [authentication] = useMutation(AUTHENTICATION);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return setCheckOTP(1);
    } else {
      return (
        <span className={seconds < 30 ? 'text-danger':'text-warning'}>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  const getOTP = async () => {
    try {
      let { data: _authentication } = await authentication({
        variables: {
          where: {
            phone_number: parseInt(userPhoneNumber()),
          },
        },
      });
      if (_authentication?.authentication?.data) {
        localStorage.setItem(USER_KEY, JSON.stringify(_authentication?.authentication));
        messageSuccess("ຂໍລະຫັດສຳເລັດແລ້ວ");
        window.location.replace("/confirmOTP/user");
      } else {
        messageError("ຂໍລະຫັດບໍ່ສຳເລັດແລ້ວ");
      }
    } catch (error) {
      console.log(error);
      messageError("ການດຳເນີນການຜິດພາດ");
    }
  };
  return (
    <Formik
      initialValues={{
        otp: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.otp) {
          errors.otp = "ກະລຸນາປ້ອນເລກ(OTP)ຂອງທ່ານກ່ອນ!";
        }
        if (values.otp.length > 6) errors.otp = "ລພຫັດ (OTP) ຕ້ອງບໍ່ເກີນ 6 ໂຕເລກ";
        if (values.otp.length < 6) errors.otp = "ລພຫັດ (OTP) ຕ້ອງບໍ່ຕ່ຳກວ່າ 6 ໂຕເລກ";
        if (values?.otp && String(values?.otp)?.length !== 6 && String(values?.otp)?.length !== 6) {
          errors.otp = `ກະລຸນາປ້ອນ (OTP) ${String(values?.otp)?.length} / 6 `;
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let { data: _verifyCode } = await verifyCode({
            variables: {
              where: {
                otp: parseInt(values?.otp),
              },
            },
          });

          if (_verifyCode?.verifyCode?.status === "success") {
            history.push("/change_password/user");
            messageSuccess("ສົ່ງສຳເລັດແລ້ວ");
            resetForm();
          }
          // if(_verifyCode?.verifyCode)
        } catch (error) {
          console.log(error);
          messageError("ການບັນທືກຂໍ້ມູນຜິດພາດ");
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,

        /* and other goodies */
      }) => (
        <>
           <div className="mt-5">
            <div className="card">
              <div className="card-body">
                <center>
                  <img src={logo_next_days} className="mt-3" />
                </center>
                <h3 className="text-center text-danger py-4">
                  ຢືນຢັນລະຫັດ OTP
                </h3>
                <label>ລະຫັດ OTP</label>
                <input
                  type="number"
                  className={
                    errors.otp
                      ? "form-control text-center is-invalid"
                      : "form-control text-center invalid"
                  }
                  name="otp"
                  onWheel={(e) => e.target.blur()}
                  value={values.otp}
                  onChange={handleChange}
                  placeholder="********"
                  style={{
                    borderRadius: "10px",
                    height: "50px",
                    fontSize: "20px",
                    letterSpacing: "20px",
                  }}
                />

                <br />
                <span className="text-danger">{errors.otp}</span>
                <br />
                {checkOTP === 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success btn-lg btn-block"
                      onClick={() => handleSubmit()}
                      style={{ borderRadius: "10px" }}
                    >
                      <i className="icon icon-send" /> ສົ່ງລະຫັດ OTP
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-lg btn-block"
                      onClick={() => getOTP()}
                      style={{ borderRadius: "10px" }}
                    >
                      <i className="icon icon-send" /> ຂໍ OTP ໃໝ່
                    </button>
                  </>
                )}
              </div>
              <center>
                <p className="text-center">
                  ກະລຸນາກວດສອບລະຫັດ OTP ທີ່ WhatsApp
                </p>
                <p className="text-center ">
                  {checkOTP === 0 ? (
                    <>
                      <Countdown
                        date={Date.now() + 60000}
                        renderer={renderer}
                      />
                    </>
                  ) : (
                    <small className="text-center text-danger">OTP ໝົດອາຍຸ</small>
                  )}
                </p>
              </center>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
