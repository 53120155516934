import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
  mutation ChangePasswordCustomer(
    $data: ChangePasswordCustomerInput!
    $where: ChangePasswordCustomerWhereInput!
  ) {
    changePasswordCustomer(data: $data, where: $where) {
      status
    }
  }
`;