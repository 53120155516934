import { gql } from "@apollo/client";
export const VERIFY_CODE = gql`
  mutation Mutation($where: VerifyCodeCustomerInput!) {
    verifyCodeCustomer(where: $where) {
      status
    }
  }
`;
export const AUTHENTICATION = gql`
  mutation AuthenticationCustomer($where: AuthenticationCustomerInput!) {
    authenticationCustomer(where: $where) {
      accessToken
      data {
        id_list
        full_name
        contact_info
        username
      }
    }
  }
`;