
import { useEffect } from 'react';
import { clearLocalStorage } from '../../helper';
import success from '../../img/check.gif'
export default function SuccessUser(){
    useEffect(()=>{
        clearLocalStorage()
    },[])
    return(
        <>
        <div className="container mt-5">
            <div className="card">
                <div className='card-body'>
                    <img src={success} className="mt-3 ml-2" width="100%" height="50%" />
                    <h3 className='text-center'>ປ່ຽນລະຫັດສຳເລັດແລ້ວ</h3>
                </div>
            </div>
        </div>
        </>
    );
}