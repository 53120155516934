// = "/company/pump/detail"
export const USER_KEY = "ANS-CHANGE-PASSWORD";
export const AU_THEN_USER = "/authentication/user";
export const CONFIRM_OTP_USER = "/verify/user";
export const CHANGE_PASSWORD_STAFF_USER = "/change_password/user";
export const SUCCESS_USER = "/success/user";

export const AU_THEN_CUSTOMER = "/authentication/customer";
export const CONFIRM_OTP_CUSTOMER = "/verify/customer";
export const CHANGE_PASSWORD_STAFF_CUSTOMER = "/change_password/customer";
export const SUCCESS_CUSTOMER = "/success/customer";



