import React from "react";
// import { USER_KEY } from "../Routes/app";
import moment from "moment";
import Notiflix from "notiflix";
import loading from "../img/loading.gif";
var start_year = new Date().getFullYear();
export const ITEM_PER_PAGE = 50;
export const aws_url_image =
  "https://anousith-bucket.s3.ap-southeast-1.amazonaws.com/images/";
export const aws_url_files =
  "https://anousith-bucket.s3.ap-southeast-1.amazonaws.com/imageregisterdrivers/";
export const aws_url_transfer_slips =
  "https://anousith-bucket.s3.ap-southeast-1.amazonaws.com/image_incomebranch/";
export const aws_url_pumps =
  "https://anousith-bucket.s3.ap-southeast-1.amazonaws.com/pumps/";

export const CHECK_BOX_STYLE = {
  width: "20px",
  height: "20px",
};
//NO NUMBER
export const NO = ({ index, numberRow, numberPage }) => {
  const no = numberRow * numberPage - numberRow;
  if (numberRow > 0) {
    return currency(no + index + 1);
  } else {
    return currency(index + 1);
  }
};
//set params
export const setParams = (key, val) => {
  const urlSearch = window.location.search;
  if (urlSearch.search(key) === -1) {
    const _symbol = urlSearch ? "&" : "";
    const _search = `${urlSearch}${_symbol}${key}=${val ? val : ""}`;
    return _search;
  } else {
    const params = new URLSearchParams(urlSearch);
    const oldValue = params.get(key);
    const newUrl = urlSearch.replace(
      `${key}=${oldValue}`,
      `${key}=${val ? val : ""}`
    );
    return newUrl;
  }
};
//clear localStorage
export const clearLocalStorage = () => {
  localStorage.removeItem(USER_KEY);
};

// best color
export const PRIMARY_COLOR = "#25476a";
export const SECONDARY_COLOR = "#1fbb62";
export const TERTIARY_COLOR = "#1b97c8";

export const USER_KEY = "ANS-CHANGE-PASSWORD";
// isMain and isPublic
export const createdAt_gte = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("YYYY-MM-DD");
};

export const createdAt_lt = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).add(1, "days").format("YYYY-MM-DD");
};

export const currentDate = (date) => {
  const _date = date ? date : new Date();
  return moment(_date).format("DD-MM-YYYY");
};

// get user role from token
const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getStaffLogin = () => {
  let json = localStorage.getItem(USER_KEY);
  let staff = JSON.parse(json);
  return staff;
};
export const userRole = () => {
  const _token = getStaffLogin()?.accessToken;
  const _object = parseJwt(_token);
  return _object?.role;
};
export const userPhoneNumber = () => {
  const _token = getStaffLogin()?.accessToken;
  const _object = parseJwt(_token);
  return _object?.phone_number;
};
export const userContact_info = () => {
  const _token = getStaffLogin()?.accessToken;
  const _object = parseJwt(_token);
  return _object?.contact_info;
};
export const userOTP = () => {
  const _token = getStaffLogin()?.accessToken;
  const _object = parseJwt(_token);
  return _object?.otp;
};
export const unixTimeFormat = (x) => {
  let res = moment.unix(x).format("YYYY-MM-DD HH:SS");
  if (res) return res;
};
// year
export const getYeear = () => {
  let getYeear = [];
  for (let date = 1990; date < start_year + 1; date++) {
    getYeear.push(date);
  }
  return getYeear;
};
//  category_financetype
export const financetype = (item) => {
  if (item === "INCOME") {
    return "ລາຍຮັບ";
  } else {
    return "ລາຍຈ່າຍ";
  }
};

export const startOfMonthThree = () => {
  // return moment().clone().startOf("month").format("YYYY-MM-DD");
  return moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD");
};

//  unitCurrency
export const unitCurrency = (item) => {
  if (item === "COD") {
    return "ເງິນສົດ";
  } else if (item === "TRANSFER") {
    return "ເງິນໂອນ";
  } else {
    return "-";
  }
};

// status category_financetype
export const statusFinancetype = (item) => {
  if (item === false) {
    return "ປິດໃຊ້ງານ";
  } else {
    return "ເປີດໃຊ້ງານ";
  }
};

// educationlevel
export const getEducationLevel = () => {
  let getYear = [];
  var start_year = new Date().getFullYear();
  for (var i = start_year; i < start_year + 10; i++) {
    getYear.push(i - 1 + "-" + i);
  }
  return getYear;
};

// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ";
      break;
    case "FEMALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
  }
  return res;
};

// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
  }
  return res;
};

export const ITEM_PAGE_LIST = [
  { itemPage: "1" },
  { itemPage: "2" },
  { itemPage: "3" },
  { itemPage: "4" },
  { itemPage: "5" },
  { itemPage: "ທັງໜົດ" },
];

export const bracket = (tag) => {
  let st = "(";
  let en = ")";
  let i = st + tag + en;
  return i;
};

export const feedbackStatus = (item) => {
  if (item === "REQUESTING") {
    return "ກຳລັງຮ້ອງຮຽນ...";
  } else if (item === "ACCEPTED") {
    return "ຮັບເລື່ອງແລ້ວ";
  } else if (item === "REJECTED") {
    return "ປະຕິເສດ";
  } else if (item === "PROCESSING") {
    return "ກຳລັງແກ້ໄຂ...";
  } else if (item === "COMPLETED") {
    return "ແກ້ໄຂສຳເລັດ";
  } else {
    return "-";
  }
};

export const reportType = (item) => {
  if (item === "SYSTEM") {
    return "ລະບົບ ແລະ ແອັບ";
  } else if (item === "SERVICE") {
    return "ການບໍລິການລູກຄ້າ";
  } else if (item === "REPLY") {
    return "ການຕອບແຊັດ";
  } else if (item === "BROKED") {
    return "ເຄື່ອງເປ່ເພ";
  } else if (item === "LOST") {
    return "ເຄື່ອງເສຍຫາຍ";
  } else if (item === "COD") {
    return "ການຮັບເງິນ COD";
  } else if (item === "VEHICLE") {
    return "ບັນຫາພາຫະນະ";
  } else if (item === "OTHER_TYPE") {
    return "ບັນຫາອື່ນໆ";
  } else {
    return "-";
  }
};

// ວັນທີເດືອນປີເລີ່ມວັນທີ ເລີ່ມເດືອນ ເລີ່ມປີ
export const startOfMonth = () => {
  return moment().clone().startOf("month").format("YYYY-MM-DD");
};

// ວັນທີເດືອນປີ ທ້າຍວັນທີ ທ້າວເດືອນ ທ້າຍປີ
export const endOfMonth = () => {
  return moment().clone().endOf("month").format("YYYY-MM-DD");
};

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  if (value != 0) return currencys;
  else if (value == 0) return "0";
  else return "";
};
export const formatDateTime_lao = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD-MM-YYYY HH:mm:ss");
  return resp;
};

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD-MM-YYYY, HH:mm");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  // moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY");
  if (dateTime) return resp;
  else return "-";
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};
// ກຳນົດ ວັນທີເວລາປັດຈຸບັນ(/)
export const formateDateTimeSlash = (slashData) => {
  let resp = moment(slashData).format("HH:mm:ss DD/MM/YYYY");
  return resp;
};
// ກຳນົດ ວັນທີເວລາປັດຈຸບັນ(/)
export const formateDateSlashes = (slashData) => {
  let resp = moment(slashData).format("DD/MM/YYYY");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days");
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("YYYY/MM/DD, HH:mm");
  return todays;
};
// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};
export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";
  }
  return result;
};

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort();
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer",
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  );
};

// =======>>> month

export const _month = [
  {
    id: "09",
    month: "ກັນຍາ",
  },
  {
    id: "10",
    month: "ຕຸລາ",
  },
  {
    id: "11",
    month: "ພະຈິກ",
  },
  {
    id: "12",
    month: "ທັນວາ",
  },
  {
    id: "01",
    month: "ມັງກອນ",
  },
  {
    id: "02",
    month: "ກຸມພາ",
  },
  {
    id: "03",
    month: "ມີນາ",
  },
  {
    id: "04",
    month: "ເມສາ",
  },
  {
    id: "05",
    month: "ພຶດສະພາ",
  },
  {
    id: "6",
    month: "ມິຖຸນາ",
  },
  {
    id: "7",
    month: "ກໍລະກົດ",
  },
  {
    id: "8",
    month: "ສິງຫາ",
  },
];
export const paymentMethodFinance = (item) => {
  if (item === "PAY_ONLINE") {
    return "ອອນລາຍ";
  } else if (item === "PAY_CASH") {
    return "ເງີນສົດ";
  } else {
    return "-";
  }
};

export const startLoading = () => {
  return Notiflix.Loading.standard("Loading...");
};
export const stopLoading = () => {
  return Notiflix.Loading.remove();
};

export const messageSuccess = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.success(e);
};

export const messageError = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.failure(e);
};
export const messageWarning = (e) => {
  Notiflix.Notify.init({ position: "center-top" });
  Notiflix.Notify.warning(e);
};

export const messageConfirm = (title, action) => {
  Notiflix.Confirm.show(
    "ເເຈ້ງເຕືອນ",
    title,
    "ຕົກລົງ",
    "ຍົກເລີກ",
    async function () {
      action();
    },
    function () {
      return false;
    }
  );
};

export const _paymentStatus = [
  { name: "SOMETIME" },
  { name: "WEEKLY" },
  { name: "MONTH" },
  { name: "YEARLY" },
];

export const _payCurrency = [
  { name: "LAK" },
  { name: "BAHT" },
  { name: "DOLLAR" },
];

export const statusSections = [
  { title: "NORTHERN" },
  { title: "CENTRAL" },
  { title: "SOUTHERN" },
];

export const covertSection = (status) => {
  if (status === "NORTHERN") {
    return "ພາກເໜືອ";
  } else if (status === "CENTRAL") {
    return "ພາກກາງ";
  } else if (status === "SOUTHERN") {
    return "ພາກໃຕ້";
  } else {
    return "-";
  }
};

export const statusCurrencyPayment = (_status) => {
  if (_status === "LAK") {
    return "ກີບ [LAK]";
  } else if (_status === "BAHT") {
    return "ບາດ [BAHT]";
  } else {
    return "ໂດລາ [US]";
  }
};

export const formatStartDate = (date) => {
  let _date = moment(date).format("YYYY-MM-DD");
  return _date;
};
export const formatEndDate = (date) => {
  let _endDate = moment(date).add(1, "days").format("YYYY-MM-DD");
  return _endDate;
};

export const loadingData = (size, content) => {
  return (
    <>
      <img
        src={loading}
        alt="loading"
        style={{ width: size ? size : 25, height: size ? size : 25 }}
      />{" "}
      {content}
    </>
  );
};

export const loadingDatalist = (size = 25, content) => {
  return (
    <span>
      <img src={loading} alt="loading" style={{ width: size }} />{" "}
      <span className="text-primary">{content || "ກຳລັງໂຫຼດຂໍ້ມູນ..."}</span>
    </span>
  );
};

export const selectedAll = (e, className = "item-list-check") => {
  if (e.target.checked) {
    const array = [];
    document.querySelectorAll(`.${className}`).forEach((elm) => {
      elm.checked = true;
      let _id = elm.value;
      array.push(_id);
    });
    return array;
  } else {
    document.querySelectorAll(".item-list-check").forEach((elm) => {
      elm.checked = false;
    });
    const array = [];
    return array;
  }
};

export const selectedOne = (e, className = "item-list-check") => {
  const elmClass = document.querySelectorAll(`.${className}`);
  if (e.target.value) {
    const array = [];
    elmClass.forEach((elm) => {
      if (elm.checked) {
        array.push(elm.value);
      }
    });
    return array;
  }
};

export const unselected = (
  id = "selected-all",
  className = "item-list-check"
) => {
  const elmClass = document.querySelectorAll(`.${className}`);
  document.querySelector(`#${id}`).checked = false;
  elmClass.forEach((elm) => {
    elm.checked = false;
  });
  const result = [];
  return result;
};


export const suserStatus = (item) => {
  if (item === "SUPER_ADMIN") {
    return "ບໍລິຫານສູງສຸດ";
  } else if (item === "IT") {
    return "ໄອທີ";
  } else if (item === "HR") {
    return "ບຸກຄາລະກອນ";
  } else if (item === "ACCOUNTANT") {
    return "ບັນຊີ";
  } else if (item === "FINANCE") {
    return "ການເງິນ";
  } else if (item === "LAW") {
    return "ກົດໝາຍ";
  } else if (item === "STATE_DIRECTOR") {
    return "ຫົວໜ້າບໍລິຫານສາຂາ";
  } else if (item === "BRANCH_DIRECTOR") {
    return "ຫົວໜ້າສາຂາ";
  } else if (item === "ADMIN") {
    return "ເອັດມິນ";
  } else if (item === "CALL_CENTER") {
    return "ພ/ງ ຕ້ອນຮັບ";
  } else if (item === "DRIVER_DIRECTOR") {
    return "ຫົົວໜ້າໂຊເເຟີ";
  } else if (item === "CUSTOMER_SERVICE") {
    return "ພ/ງ ບໍລິການລູກຄ້າ";
  } else if (item === "DRIVER") {
    return "ໄລເດີ້";
  } else if (item === "AUDITION_DIRECTOR") {
    return "ຫົວໜ້າສູນຄັດແຍກ";
  } else if (item === "AUDITION_STAFF") {
    return "ພ/ງ ສູນຄັດແຍກ";
  } else {
    return "-";
  }
};

export const multipleItemStatus = [
  "ORIGIN_BRANCH_RECEIVED_FORWARD",
  "TRANSIT_TO_DEST_BRANCH",
  "DEST_BRANCH_RECEIVED_FORWARD",
  "TRANSIT_TO_ORIGIN_BRANCH",
  "ORIGIN_BRANCH_RECEIVED_BACKWARD",
  "DEST_BRANCH_RECEIVED_BACKWARD",
  "COMPLETED",
];

export const statusRegisterPartner = (status) => {
  if (status === "REQUESTED") {
    return "ສະໝັກໃໝ່";
  } else if (status === "APPROVED") {
    return "ອານຸມັດແລ້ວ";
  } else if (status === "REJECTED") {
    return "ປະຕິເສດ";
  } else if (status === "CANCELED") {
    return "ຍົກເລິກ";
  } else {
    return "-";
  }
};
