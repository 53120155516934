import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import * as ROUTES from "./app";

import { USER_KEY } from "../helper";
import AuThenUser from "../Screens/authenUser";
import AuThenCusTomer from "../Screens/authenCustomer";
import ConFirmOTPUser from "../Screens/confirmOTPUser";
import ConFirmOTPCusTomer from "../Screens/confirmOTPCustomer";
import ChangePasswordUser from "../Screens/changePasswordUser";
import SuccessUser from "../Screens/successUser";
import ChangePasswordCustomer from "../Screens/changePasswordCustomer";
import SuccessCustomer from "../Screens/successUserCustomer";

function Routes() {
  const _local = localStorage.getItem(ROUTES.USER_KEY);
  return (
    <Router>
      <Switch>
        {!_local ? (
          <React.Fragment>
            {/* <Route path="/">
              <Redirect to="/authentication/user" />
            </Route> */}
            <Route exact path="/authentication/user" component={AuThenUser} />
            <Route
              exact
              path="/authentication/customer"
              component={AuThenCusTomer}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route
              render={({ location, history }) => (
                <React.Fragment>
                  <Route exact path="/">
                    <Redirect to="/authentication/user" />
                  </Route>
                    <PrivateRoute
                      path={`${ROUTES.AU_THEN_USER}`}
                      exact
                      component={(props) => <AuThenUser />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.CONFIRM_OTP_USER}`}
                      exact
                      component={(props) => <ConFirmOTPUser />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.CHANGE_PASSWORD_STAFF_USER}`}
                      exact
                      component={(props) => <ChangePasswordUser />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.SUCCESS_USER}`}
                      exact
                      component={(props) => <SuccessUser />}
                    />

                    <PrivateRoute
                      path={`${ROUTES.AU_THEN_CUSTOMER}`}
                      exact
                      component={(props) => <AuThenCusTomer />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.CONFIRM_OTP_CUSTOMER}`}
                      exact
                      component={(props) => <ConFirmOTPCusTomer />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.CHANGE_PASSWORD_STAFF_CUSTOMER}`}
                      exact
                      component={(props) => <ChangePasswordCustomer />}
                    />
                    <PrivateRoute
                      path={`${ROUTES.SUCCESS_CUSTOMER}`}
                      exact
                      component={(props) => <SuccessCustomer />}
                    />
                </React.Fragment>
              )}
            />
          </React.Fragment>
        )}
      </Switch>
    </Router>
  );
}

export default Routes;
