import React from "react";
import "moment/locale/lo";
import "./index.css";
import {USER_KEY } from "./helper";
import _ from "lodash";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Routes from "./Routes";
const api = "https://authentication.api.anousith.express/graphql";
// const api = "http://localhost:9000/graphql"
const AppContext = React.createContext();
export default function App() {

  const authLink = setContext((_, { headers }) => {
    const _local = localStorage.getItem(USER_KEY);
    let token = JSON.parse(_local);
    token = token?.accessToken;
    // console.log({token});
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: from([
      authLink.concat(
        createHttpLink({
          uri: api,
        })
      ),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  return (
    <AppContext.Provider>
      <ApolloProvider client={client}>
          <Routes />
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export { AppContext };