import { useMutation } from "@apollo/client";
import logo from "../../img/key.png";
import { Formik } from "formik";
import { messageError, messageSuccess, USER_KEY } from "../../helper";
import { useHistory } from "react-router-dom";
import { AUTHENTICATION } from "./apollo";
import { CONFIRM_OTP_CUSTOMER } from "../../Routes/app";
export default function AuThenCusTomer() {
  const history = useHistory();
  const [authentication] = useMutation(AUTHENTICATION);
  return (
    <Formik
      initialValues={{
        changePassword: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.changePassword) {
          errors.changePassword = "ກະລຸນາປ້ອນເບີໂທຂອງທ່ານກ່ອນ!";
        }
        if (
          values?.changePassword &&
          String(values?.changePassword)?.length !== 8 &&
          String(values?.changePassword)?.length !== 8
        ) {
          errors.changePassword = `ກະລຸນາປ້ອນເບີໂທ ${
            String(values?.changePassword)?.length
          } /8 `;
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let { data: _authentication } = await authentication({
            variables: {
              where: {
                contact_info: String(values?.changePassword),
              },
            },
          });

          if (_authentication?.authenticationCustomer?.data) {
            localStorage.setItem(
              USER_KEY,
              JSON.stringify(_authentication?.authenticationCustomer)
            );
            resetForm();
            messageSuccess("ຂໍລະຫັດສຳເລັດແລ້ວ");
            window.location.replace(`${CONFIRM_OTP_CUSTOMER}`);
          } else {
            messageError("ຂໍລະຫັດບໍ່ສຳເລັດແລ້ວ");
          }
        } catch (error) {
          console.log(error?.Error);
          messageError("ເບີໂທບໍຖຶກຕ້ອງ ຫຼື ທ່ານຂໍ OTP ເກີນກຳນົດ ຕໍ່ມື້ ແລ້ວ");
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,

      }) => (
        <>
          <div className="p-0">
            <div class="card mt-5">
              <div class="card-body">
                <div className="text-center">
                  <img
                    src={logo}
                    className="mt-3"
                    style={{
                      width: 150,
                      height: 150,
                    }} 
                  />
                </div>
                <h2 className="text-center fw-bolder my-4 text-danger">
                  ຂໍລະຫັດ OTP
                </h2>
                <label className="text-start">ເບີໂທ(+85620)</label>
                <input
                  type="number"
                  className={
                    errors.changePassword
                      ? "form-control form-control-lg is-invalid"
                      : "form-control form-control-lg invalid"
                  }
                  name="changePassword"
                  value={values.changePassword}
                  onChange={handleChange}
                  onWheel={(e) => e.target.blur()}
                  placeholder="********"
                  style={{
                    borderRadius: "10px",
                    height: "50px",
                    fontSize: "20px",
                  }}
                />
                <span className="text-danger">{errors.changePassword}</span>
                <br />
                <br />
                <button
                  type="button"
                  className="btn btn-success btn-lg btn-block"
                  onClick={() => handleSubmit()}
                  style={{ borderRadius: "10px" }}
                >
                  <i className="icon icon-send" /> ຂໍລະຫັດ OTP
                </button>
                <center className="mt-5">
                  <p>ລະຫັດຈະຖືກສົ່ງໄປຫາເບີຂອງທ່ານ.</p>
                  <p className="text-danger">
                    <i className="icon-info" /> ສາມາດ ຂໍໄດ້ 3ຄັ້ງ/ມື້
                  </p>
                </center>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
