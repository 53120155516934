import { gql } from "@apollo/client";
export const AUTHENTICATION = gql`
  mutation AuthenticationCustomer($where: AuthenticationCustomerInput!) {
    authenticationCustomer(where: $where) {
      accessToken
      data {
        id_list
        full_name
        contact_info
        username
      }
    }
  }
`;