import { gql } from "@apollo/client";
export const VERIFY_CODE = gql`
mutation VerifyCode($where: VerifyCodeInput!) {
  verifyCode(where: $where) {
    status
  }
}
`;
export const AUTHENTICATION = gql`
mutation Authentication($where: AuthenticationInput!) {
  authentication(where: $where) {
    accessToken
    data {
      id_user
      first_name
      last_name
      phone_number
    }
  }
}
`;