import { useEffect } from "react";
import { clearLocalStorage } from "../../helper";
import success from "../../img/check.gif";
export default function SuccessCustomer() {
  useEffect(() => {
    clearLocalStorage();
    if (window.parent) {
      setTimeout(() => {
        window.parent.postMessage("changerPasswordSuccess", "*");
      }, 2000);
    }
  }, []);
  return (
    <>
      <div className="container mt-5">
        <div className="card">
          <div className="card-body">
            <img src={success} className="mt-3 ml-2" width="100%" height="50%" />
            <h3 className="text-center">ປ່ຽນລະຫັດສຳເລັດແລ້ວ</h3>
          </div>
        </div>
      </div>
    </>
  );
}
