import { useMutation } from "@apollo/client";
import logo_next_days from "../../img/key.png";
import { Formik } from "formik";
import { messageError, messageSuccess, USER_KEY } from "../../helper";
import { useHistory } from "react-router-dom";
import { AUTHENTICATION } from "./apollo";
export default function AuThenUser() {
  const history = useHistory();
  const [authentication] = useMutation(AUTHENTICATION);
  return (
    <Formik
      initialValues={{
        changePassword: "",
      }}
      enableReinitialize={false}
      validate={(values) => {
        const errors = {};
        if (!values.changePassword) {
          errors.changePassword = "ກະລຸນາປ້ອນເບີໂທຂອງທ່ານກ່ອນ!";
        }

        if (
          values?.changePassword &&
          String(values?.changePassword)?.length !== 8 &&
          String(values?.changePassword)?.length !== 8
        ) {
          errors.changePassword = `ກະລຸນາປ້ອນເບີໂທ ${
            String(values?.changePassword)?.length
          } /8 `;
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          let { data: _authentication } = await authentication({
            variables: {
              where: {
                phone_number: parseInt(values?.changePassword),
              },
            },
          });
          if (_authentication?.authentication?.data) {
            localStorage.setItem(
              USER_KEY,
              JSON.stringify(_authentication?.authentication)
            );
            resetForm();
            messageSuccess("ຂໍລະຫັດສຳເລັດແລ້ວ");
            window.location.replace("/verify/user");
          } else {
            messageError("ຂໍລະຫັດບໍ່ສຳເລັດແລ້ວ");
          }
        } catch (error) {
          if (
            error?.graphQLErrors[0]?.message ===
            "Error: You have exceeded the limit of sending OTP today. Please try again tomorrow."
          ) {
            messageError(
              "ທ່ານໄດ້ສົ່ງ OTP ເກີນ 3 ຄັ້ງ ກະລຸນາລອງອີກຄັ້ງ ມື້ຕໍ່ໄປ"
            );
          } else {
            messageError("ເບີໂທຂອງທ່ານ ບໍ່ຖືກຕ້ອງ");
          }
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        /* and other goodies */
      }) => (
        <div className="p-0">
          <div class="card mt-5">
            <div class="card-body">
              <div className="text-center">
                <img src={logo_next_days} className="mt-3" />
              </div>
              <h2 className="text-center fw-bolder my-4 text-danger">
                ຂໍລະຫັດ OTP
              </h2>
              <label className="text-start">ເບີໂທ(+85620)</label>
              <input
                type="number"
                className={
                  errors.changePassword
                    ? "form-control form-control-lg is-invalid"
                    : "form-control form-control-lg invalid"
                }
                name="changePassword"
                value={values.changePassword}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                placeholder="********"
                style={{
                  borderRadius: "10px",
                  height: "50px",
                  fontSize: "20px",
                }}
              />
              <span className="text-danger">{errors.changePassword}</span>
              <br />
              <br />
              <button
                type="button"
                className="btn btn-success btn-lg btn-block"
                onClick={() => handleSubmit()}
                style={{ borderRadius: "10px" }}
              >
                <i className="icon icon-send" /> ຂໍລະຫັດ OTP
              </button>
              <center className="mt-5">
                <p>ລະຫັດຈະຖືກສົ່ງໄປຫາເບີຂອງທ່ານ.</p>
                <p className="text-danger">
                  <i className="icon-info" /> ສາມາດ ຂໍໄດ້
                  3ຄັ້ງ/ມື້
                </p>
              </center>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
